import type { QBusEventMap, QBusEventEnum } from "@otto-ec/event-q-bus";
import { globalEvents } from "../../common/src/utils";

/**
 *
 */
export type DropDownEvents = QBusEventMap<{
  /**
 *
 *
 *
 *
 *
 *
 *
 *
 */
  "assets.dropdown.init": [selector?: string];
}>;

const EVENTS: QBusEventEnum<DropDownEvents> = {
  init: "assets.dropdown.init",
};

/*                                              */
const dropDownEvents = window.o_global.events<DropDownEvents>();

/*               */
dropDownEvents.on(EVENTS.init, (selector = "") => {
  const dropdownElements = document.querySelectorAll<HTMLSelectElement>(
    `${selector} .js-p_dropdown__element, ${selector} .js-pl_dropdown__element`
  );

  function onChange(this: HTMLSelectElement): void {
    this.setAttribute("data-value", this.value);
  }

  Array.from(dropdownElements).forEach((el) => {
    /*                    */
    el.addEventListener("change", onChange);

    /*                                                      */
    onChange.call(el);

    /*                                                                        */
    window.setTimeout(() => {
      el.nextElementSibling?.classList.remove("js-p_label", "js-pl_label");
    }, 100);
  });
});

globalEvents.once("assets.events.documentReady", () => {
  dropDownEvents.emit(EVENTS.init, "body");
});
