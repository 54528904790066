import type { QBusEventMap } from "@otto-ec/event-q-bus";
import type { QBusEventEnum } from "@otto-ec/global-resources/event-q-bus";
import type { Sheet } from "./Sheet";
import type { SheetAPI } from "./sheetTypes";

export type SheetEvents = QBusEventMap<{
  "assets.sheet.activeSheetChanged": [Sheet | undefined];
  "assets.sheet.closed": [{ closeType: SheetCloseTypes; id: string }];
  "assets.sheet.contentLoaded": [{ id: string }];
  "assets.sheet.contentLoadingFailed": [
    { id: string; errorCode: number | undefined; errorText: string },
  ];
  "assets.sheet.contentReverted": [];
  "assets.sheet.contentSwitched": [HTMLElement];
  "assets.sheet.opened": [SheetAPI];
}>;

export const SHEET_EVENTS: QBusEventEnum<SheetEvents> = {
  activeSheetChanged: "assets.sheet.activeSheetChanged",
  closed: "assets.sheet.closed",
  contentLoaded: "assets.sheet.contentLoaded",
  contentLoadingFailed: "assets.sheet.contentLoadingFailed",
  contentReverted: "assets.sheet.contentReverted",
  contentSwitched: "assets.sheet.contentSwitched",
  opened: "assets.sheet.opened",
} as const;

export const SHEET_CLOSE_TYPE = {
  CLOSED_WITH_X: "closedWithX",
  CLOSED_WITH_CURTAIN_CLICK: "closedWithCurtainClick",
  CLOSED_WITH_DRAG: "closedWithDrag",
  CLOSED_PROGRAMMATICALLY: "closedProgrammatically",
} as const;

export type SheetCloseTypes = (typeof SHEET_CLOSE_TYPE)[keyof typeof SHEET_CLOSE_TYPE];
