/*                                        */
import "./common/src/namespaces";

/*            */
import "./300_Gliederung/199_pl_expander/pl_expander";
import "./text-expander/src/index";
import "./legacy/300_textexpander/textexpander";
import "./300_Gliederung/399_pl_accordion/pl_accordion";
import "./legacy/400_accordion/accordion";

import "./400_Interaktionen/650_pl_switch/pl_switch";
import "./legacy/700_tooltip/tooltip";
import "./legacy/800_scroll-top-button/scroll-top-button";
import "./legacy/300_formular-eingabefeld/formular-eingabefeld";
import "./legacy/400_formular-auswahlfeld/formular-auswahlfeld";

import "./520_Formular_Material/300_pl_input/pl_input";
import "./520_Formular_Material/400_pl_label/pl_label";
import "./520_Formular_Material/700_pl_counter/pl_counter";

import "./600_Status_und_Feedback/199_pl_banner/pl_banner";
import "./legacy/200_hinweiskommunikation/hinweiskommunikation";

import "./legacy/100_pali-layer/pali-layer";
import "./legacy/200_popup/popup";

import type { Sheet } from "./700_Layer_und_Popup/400_pl_sheet";
import type { Presets } from "./legacy/100_grundprinzipien/animations";

/*   */
import { carousel } from "./carousel/src/head";

/*            */
export * from "./text-expander/src";
export * from "./400_Interaktionen/699_pl_tooltip";
export * from "./400_Interaktionen/750_pl_snackbar";
export * from "./520_Formular_Material/500_pl_dropdown/index";
export * from "./700_Layer_und_Popup/400_pl_sheet";
export * from "./700_Layer_und_Popup/299_pl_focussed-dialog";
export * from "./legacy/100_grundprinzipien/animations";

window.o_pattern = { carousel };

/**
 *
 *
 */
export interface Pali {
  plAccordion: { clickHandler(event: Event): void; toggleContent(target: HTMLElement): void };
  /*                                                                    */
  sheet: typeof Sheet;
  /*                                                                                      */
  sheetBuilder: typeof Sheet;
  /**
 *
 *
 */
  presets: Presets;
}
